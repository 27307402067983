import ContentArea from '../../Organisms/ContentArea/ContentArea';
import StandardPageModel from '../../StandardPage/Models/StandardPageModel.interface';
import useCurrentPage from '../../Shared/Hooks/useCurrentPage';
import Grid from '../../Atoms/Grids/Grid';
import { styled } from '../../stitches.config';
import { H2 } from '../../Atoms/Typography/Headings/Heading';
import { GridWrapper } from '../../StartPage/StartPage';
import { useTopPadding } from '../../Shared/Common/Helpers';
import { StructuredPageData } from '../../Molecules/StructuredData/StructuredPageData';

function StandardPage() {
  const { pageHeading, pageIntroText, contentArea, bodyText } =
    useCurrentPage<StandardPageModel>();

  const pageData = useCurrentPage<StandardPageModel>();

  const usePadding = useTopPadding({ firstBlock: contentArea[0] });

  return (
    <main>
      <StructuredPageData page={pageData} />

      {pageHeading && (
        <TopContent>
          <H2 css={HeadingStyle}>{pageHeading}</H2>
          <Intro>{pageIntroText}</Intro>
          <BodyText
            dangerouslySetInnerHTML={{
              __html: bodyText,
            }}
          />
        </TopContent>
      )}
      <GridWrapper useTopPadding={usePadding}>
        <Grid type={12} data-content-area>
          {Array.isArray(contentArea) && (
            <ContentArea childItems={contentArea} />
          )}
        </Grid>
      </GridWrapper>
    </main>
  );
}

const TopContent = styled('div', {
  my: 6,
  mx: 'auto',
  w: '100%',
  maxW: '$blockMaxWidthMobile',
  px: 4,
  '@mediaMinLarge': {
    my: 13,
    py: 0,
  },
  '@mediaMinMedium': {
    maxW: '$maxWidthSmall',
  },
});

const HeadingStyle = {
  maxW: '$maxWidthSmall',
  fs: 18,
  fontWeight: '$fw300',
  mb: 6,
  '@mediaMinLarge': {
    fs: 24,
    mb: 8,
  },
};

const Intro = styled('p', {
  fs: 8,
  fontWeight: '$fw300',
  lineHeight: '$lh15',
  ls: '$ls113',
  mb: 8,
  '@mediaMinLarge': {
    fs: 9,
  },
});

const BodyText = styled('div', {
  lineHeight: '$lh1625',
  ls: '$ls06',
});

export default StandardPage;
